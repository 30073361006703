import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatDialogModule } from "@angular/material/dialog";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { CanActivateAuthGuard } from './can-activate.authguard';
import { APP_BASE_HREF } from '@angular/common';
import { APP_CONFIG, GLM_MIGRATE_CLIENT_CONFIG } from './app.config';
import { AUTH_CONFIG, USER_AUTH_CONFIG } from './auth.config';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { PostSignupPageComponent } from './pages/post-signup-page/post-signup-page.component';
import { ConvertDialogComponent } from './components/convert-dialog/convert-dialog.component';
import { DeleteWarningDialogComponent } from './components/delete-warning-dialog/delete-warning-dialog.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FileUploadModule } from 'ng2-file-upload';
import { MigrationPageComponent } from './pages/migration-page/migration-page.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { GridModule } from "@progress/kendo-angular-grid";
import { HeaderComponent } from './components/header/header.component';
import { AdvancedOptionsPanelComponent } from './components/advanced-options-panel/advanced-options-panel.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    PostSignupPageComponent,
    SpinnerComponent,
    ConvertDialogComponent,
    DeleteWarningDialogComponent,
    MigrationPageComponent,
    WelcomePageComponent,
    HeaderComponent,
    AdvancedOptionsPanelComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
    GridModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule, MatInputModule,
    MatSelectModule,
    FileUploadModule,
    ToastrModule.forRoot({
      positionClass: 'toast-center-center',
      preventDuplicates: true,
      maxOpened: 1
    })
  ],
  providers: [
    CanActivateAuthGuard,
    { provide: APP_CONFIG, useValue: GLM_MIGRATE_CLIENT_CONFIG },
    { provide: AUTH_CONFIG, useValue: USER_AUTH_CONFIG },
    { provide: APP_BASE_HREF, useValue: GLM_MIGRATE_CLIENT_CONFIG.baseHref }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    if (GLM_MIGRATE_CLIENT_CONFIG.production === true) {
      // GLM_MIGRATE_CLIENT_CONFIG.serverUrl = window.location.origin; 
    }
  }
}
