import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LpsAdvancedOptions } from 'src/app/models/lps-advanced-options';

@Component({
  selector: 'advanced-options-panel',
  templateUrl: './advanced-options-panel.component.html',
  styleUrls: ['./advanced-options-panel.component.css']
})



export class AdvancedOptionsPanelComponent implements OnInit {

  @Output() validationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public dropImageExtensions: boolean;
  public imagesParentName: string;
  public layoutsParentName: string;
  public dataServicesParentName: string;
  public createVersions: boolean;
  public usePrinterNumber: boolean;
  public adjustUnsupportedDpi: boolean;

  public truncateData: boolean;
  public jdbcUser: string;
  public jdbcPass: string;
  public jdbcUrl: string;
  public useLpsSchema: boolean;
  public schemaOverride: string;
  public inheritJdbc: boolean;



  constructor() { }

  ngOnInit(): void {
    this.dropImageExtensions = false;
    this.imagesParentName = '0/Images/User Images';
    this.layoutsParentName = '0/Layouts';
    this.dataServicesParentName = '0/DataServices';
    this.createVersions = true;
    this.usePrinterNumber = false;
    this.adjustUnsupportedDpi = false;
    this.truncateData = false;
    this.jdbcUser = '';
    this.jdbcPass = '';
    this.jdbcUrl = '';
    this.useLpsSchema = false;
    this.schemaOverride = '';
    this.inheritJdbc = true;
  }


  public getAdvancedOptionSelections(): LpsAdvancedOptions {
    const advancedOptionSelections: LpsAdvancedOptions = {
      dropImageExtensions: this.dropImageExtensions,
      imagesParentName: this.imagesParentName,
      layoutsParentName: this.layoutsParentName,
      dataServicesParentName: this.dataServicesParentName,
      createVersions: this.createVersions,
      usePrinterNumber: this.usePrinterNumber,
      adjustUnsupportedDpi: this.adjustUnsupportedDpi,
      truncateData: this.truncateData,
      jdbcUser: this.jdbcUser,
      jdbcPass: this.jdbcPass,
      jdbcUrl: this.jdbcUrl,
      useLpsSchema: this.useLpsSchema,
      schemaOverride: this.schemaOverride,
      inheritJdbc: this.inheritJdbc
    };
    return advancedOptionSelections;
  }

  public validateFields() {
    const isValid = this.imagesParentName.length > 0 && this.layoutsParentName.length > 0 && this.dataServicesParentName.length > 0;
    this.validationEvent.emit(isValid);
  }
}