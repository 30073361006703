<div id="advanced_options_panel">
    <div class="table-responsive">
        <table class="table table-hover table-sm">
            <tbody>
                <tr>
                    <td class="propertyName">Drop Image Extensions</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="dropImageExtensions" />
                        <div class="hint">Whether to drop the extension on image names (eg. ".jpg", ".pcx").</div>
                    </td>
                </tr>
                <tr>
                    <td class="propertyName" [ngClass]="{'errorHighlight': imagesParentName.length == 0}">Images Parent
                        Name</td>
                    <td class="propertyValue">
                        <input class="propertyText" [(ngModel)]="imagesParentName" (change)="validateFields()" />
                        <div class="errorMessage" [ngClass]="{'hidden': imagesParentName.length !== 0}">
                            'Images Parent Name' is required
                        </div>
                        <div class="hint">Specifies parent entity name where images were imported</div>
                    </td>
                </tr>
                <tr>
                    <td class="propertyName" [ngClass]="{'errorHighlight': layoutsParentName.length == 0}">Layouts Parent Name</td>
                    <td class="propertyValue">
                        <input class="propertyText" [(ngModel)]="layoutsParentName" (change)="validateFields()" />
                        <div class="errorMessage" [ngClass]="{'hidden': layoutsParentName.length !== 0}">
                            'Layouts Parent Name' is required
                        </div>
                        <div class="hint">Specifies parent entity name where layouts were/will be imported</div>
                    </td>
                </tr>
                <tr>
                    <td class="propertyName" [ngClass]="{'errorHighlight': dataServicesParentName.length == 0}">Data
                        Services Parent Name</td>
                    <td class="propertyValue">
                        <input class="propertyText" [(ngModel)]="dataServicesParentName" (change)="validateFields()" />
                        <div class="errorMessage" [ngClass]="{'hidden': dataServicesParentName.length !== 0}">
                            'Data Services Parent Name' is required
                        </div>
                        <div class="hint">Specifies parent entity name where data services referenced by LPS labels
                            were/will be added/imported</div>
                    </td>
                </tr>

                <tr>
                    <td class="propertyName">Create Versions</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="createVersions" />
                        <div class="hint">If set, all versionable objects will get a default version</div>
                    </td>
                </tr>

                <tr>
                    <td class="propertyName">Use Printer Number</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="usePrinterNumber" />
                        <div class="hint">If set, the printer number will be used as the printer name. The printer
                            number will be left padded with zeros to a minimum of 3 digits</div>
                    </td>
                </tr>


                <tr>
                    <td class="propertyName">Adjust Unsupported DPI</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="adjustUnsupportedDpi" />
                        <div class="hint">If a DPI is not one of supported dpis, the dpi will be changed to the closest
                            one. Supported DPIs are [203,300,600,1200].</div>
                    </td>
                </tr>


                <tr>
                    <td class="propertyName">Truncate Data</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="truncateData" />
                        <div class="hint">Specifies whether data should be truncated to the maximum characters
                            configured for a field. This setting should only be set to false if the LPS system had
                            BPTruncateData set to false in the llmwdn32.ini configuration file</div>
                    </td>
                </tr>
 
                <tr class="sectionHeader">
                    <td colspan="2">ODBC to JDBC</td>
                </tr>

                <tr>
                    <td class="propertyName">JDBC Username</td>
                    <td class="propertyValue"><input class="propertyText" type="text" [(ngModel)]="jdbcUser" />
                        <div class="hint">If an ODBC data source is encountered, use this user name for any generated
                            JDBC Data Service conversions in both the data source(s) (on the label(s)) and data services
                            (the JDBC data services create by the migration of the label)</div>
                    </td>
                </tr>

                <tr>
                    <td class="propertyName">JDBC Password</td>
                    <td class="propertyValue"><input class="propertyText" type="password" [(ngModel)]="jdbcPass" />
                        <div class="hint">If an ODBC data source is encountered, use this password for any generated JDBC Data Service conversions in both the data source(s) (on the label(s)) and data services (the JDBC data services create by the migration of the label).</div>
                    </td>
                </tr>

                <tr>
                    <td class="propertyName">JDBC URL</td>
                    <td class="propertyValue"><input class="propertyText" type="text" [(ngModel)]="jdbcUrl" />
                        <div class="hint">Specifies the JDBC URL of the database that Spectrum will use for any odbc data sources encountered in this label document. Will be applied to both data source(s) (on the label(s)) and data services (the JDBC data services create by the migration of the label)</div>
                    </td>
                </tr>

                <tr>
                    <td class="propertyName">Use LPS Schema</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="useLpsSchema" />
                        <div class="hint">If an ODBC data source is encountered, use the schema name found in the LPS report for any generated SELECT statements in both the data source(s) (on the label(s)) and data services (the JDBC data services create by the migration of the label)</div>
                    </td>
                </tr>

                <tr>
                    <td class="propertyName">Override Schema</td>
                    <td class="propertyValue"><input class="propertyText" type="text" [(ngModel)]="schemaOverride" />
                        <div class="hint">If an ODBC data source is encountered, use this specified schema name for any generated SELECT statements. Takes precedence over -useLpsSchema.</div>
                    </td>
                </tr>
                
                <tr>
                    <td class="propertyName">Inherit JDBC Settings</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="inheritJdbc" />
                        <div class="hint">Specifies whether JDBC Data Sources on labels inherit URL/username/password from their parent Data Services. If true, JDBC Data Sources on labels will not contain URL/User/Password override parameters, regardless of whether -jdbcUrl, -jdbcUser, and/or -jdbcPass were specified</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>