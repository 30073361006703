import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { FileItem, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { APP_CONFIG, AppConfig } from "src/app/app.config";
import { AUTH_CONFIG, AuthConfig } from "src/app/auth.config";
import { ConvertDialogComponent } from "src/app/components/convert-dialog/convert-dialog.component";
import { DeleteWarningDialogComponent } from "src/app/components/delete-warning-dialog/delete-warning-dialog.component";
import { MigrationFile } from "src/app/models/migration-file";
import { AuthService } from "src/app/services/auth.service";
import { MigrationService } from "src/app/services/migration.service";
import { Migration } from "./migration-page.interface";
import { firstValueFrom } from "rxjs";

@Component({
    templateUrl: './migration-page.component.html',
    styleUrls: ['./migration-page.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MigrationPageComponent implements OnInit {
    readonly MIGRATED_EXT = '_migrated.zip';
    readonly PING_DELAY = 15000;
    @ViewChild('fileInput') fileInput: ElementRef;
    uiProgress = false;
    uploader: FileUploader = new FileUploader({
        url: 'tempurl',
        method: 'PUT',
        disableMultipart: true,
    });
    convertedFiles: MigrationFile[] = [];
    convertingFiles: MigrationFile[] = [];
    uploadedFiles: MigrationFile[] = [];
    httpError?: Error;
    fileName = '';
    loading = false;
    response = '';
    timeInterval: NodeJS.Timeout;
    count = 0;
    isValid = true;
    filesBeingWorkedOn: string[] = [];
    filesBeingDeleted: string[] = [];
    conversion: string;
    postAdvancedOptions = false;

    tableConverted: Migration.Table = {
        field: '',
        fileNameDirection: 'asc',
        fileSizeDirection: 'asc',
        createDateDirection: 'asc',
        statusDirection: 'asc',
        array: this.convertedFiles
    }
    tableConverting: Migration.Table = {
        field: '',
        fileNameDirection: 'asc',
        fileSizeDirection: 'asc',
        createDateDirection: 'asc',
        statusDirection: 'asc',
        array: this.convertingFiles
    }
    tableUploaded: Migration.Table = {
        field: '',
        fileNameDirection: 'asc',
        fileSizeDirection: 'asc',
        createDateDirection: 'asc',
        statusDirection: 'asc',
        array: this.uploadedFiles
    }
    constructor(
        @Inject(APP_CONFIG) public appConfig: AppConfig,
        @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
        private migrationService: MigrationService, private dialog: MatDialog,
        private authService: AuthService) {
    }

    ngOnInit(): void {
        this.refreshFileList();

        // File Uploader events
        // After adding a file with the uploader
        this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
            // Set the file name based on the selected FileItem 
            this.fileName = fileItem.file && fileItem.file.name ? fileItem.file.name : '';
            // Don't send credentials because the URL will already have it set
            fileItem.withCredentials = false;
        };

        // After all files in the queue are done then refresh the list
        this.uploader.onCompleteAll = () => {
            this.refreshFileList();
        };

        // If an item errors show an alert
        this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
            alert('Error uploading the file.');
        }

        // If an item upload is cancelled show an alert
        this.uploader.onCancelItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
            alert('The file upload has been cancelled.');
        }

        // Default handler for responses
        this.uploader.response.subscribe({
            next: (result: string) => {
                this.response = result;
                this.clearUploadStatus()
            }, error: (error: Error) => {
                this.response = error.message;
                this.clearUploadStatus()
            }
        });
        this.startPingingServer();
    }

    private startPingingServer() {
        this.timeInterval = setInterval(() => {
            this.refreshFileList();
        }, this.PING_DELAY);
    }

    private refreshFileList() {
        const convertedFiles: MigrationFile[] = [];
        const convertingFiles: MigrationFile[] = [];
        const uploadedFiles: MigrationFile[] = [];
        this.httpError = undefined;
        this.migrationService.getFileList().subscribe({
            next: (data) => {
                this.count++;
                if (data instanceof Array) {
                    data.forEach(file => {
                        file.fileSizeDisplay = this.formatBytes(file.fileSize, 2);
                        if (file.createDate) {
                            file.createDateDisplay = moment(file.createDate).format('MM/DD/yyyy HH:mm');
                        }
                        file.compareAvailable = (file.isZebra && file.isZebra.toLowerCase() === 'true') ? true : false;
                        file.errored = (!!file.filename && file.filename.endsWith('errors.txt'));
                    });
                    data.forEach(item => {
                        switch (item.status) {
                            case 'Converted':
                                if (this.filesBeingDeleted.includes(item.filename)) {
                                    item.deleting = true;
                                }
                                convertedFiles.push(item);
                                break;
                            case 'Uploaded':
                                if (this.filesBeingWorkedOn.includes(this.getMigratedExtensionName(item.filename))) {
                                    item.loading = true;
                                } else { item.loading = false; }
                                if (this.filesBeingDeleted.includes(item.filename)) {
                                    item.deleting = true;
                                }
                                uploadedFiles.push(item);
                                break;
                            case 'In Progress':
                                // Remove the filename from the list
                                if (this.filesBeingWorkedOn.includes(item.filename)) {
                                    item.loading = false;
                                    this.filesBeingWorkedOn.splice(this.filesBeingWorkedOn.findIndex(x => x === item.filename), 1);
                                }
                                if (this.filesBeingDeleted.includes(item.filename)) {
                                    item.deleting = true;
                                }
                                convertingFiles.push(item);
                                break;
                        }
                    });
                    this.tableConverted.array = convertedFiles;
                    if (this.tableConverted.field) {
                        this.sortTable(this.tableConverted);
                    }
                    this.convertedFiles = this.tableConverted.array;

                    this.tableUploaded.array = uploadedFiles;
                    if (this.tableUploaded.field) {
                        this.sortTable(this.tableUploaded);
                    }
                    this.uploadedFiles = this.tableUploaded.array;

                    this.tableConverting.array = convertingFiles;
                    if (this.tableConverting.field) {
                        this.sortTable(this.tableConverting);
                    }
                    this.convertingFiles = this.tableConverting.array;
                }
            },
            error: (error) => {
                this.httpError = error;
            }
        });
    }

    private clearUploadStatus() {
        this.fileInput.nativeElement.value = "";
        this.loading = false;
        this.isValid = true;
    }

    onUploadClick() {
        this.loading = true;
        this.filesBeingWorkedOn.push(this.fileName);
        const sub = this.migrationService.getUploadFileUrl(this.fileName).subscribe(response => {
            this.uploader.setOptions({
                url: response.uploadS3Url,
                headers: [{ name: 'Content-Type', value: 'application/zip' }, { name: 'x-amz-tagging', value: 'status=Uploaded' }]
            });
            this.uploader.uploadAll();
            this.fileName = '';
            if (sub) { sub.unsubscribe(); }
        });
    }

    private formatBytes(bytes: number, decimals = 2) {
        if (!+bytes) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    onCompareClick(file: MigrationFile) {
        const sub = this.migrationService.compare(file.filename).subscribe(response => {
            if (sub) { sub.unsubscribe(); }
        });
    }

    onDownloadClick(file: MigrationFile) {
        let sub = this.migrationService.getDownloadFileLink(file.filename).subscribe(response => {
            window.location.assign(response.replace('https: ', 'https:'));
            sub.unsubscribe();
        });
    }

    onLpsArtifactsClick() {
        const sub = this.migrationService.getDownloadFileLink('GetLPSArtifacts.zip').subscribe(response => {
            window.location.assign(response.replace('https: ', 'https:'));
            if (sub) { sub.unsubscribe(); }
        });
    }

    public logout() {
        this.authService.logout();
    }

    private getMigratedExtensionName(fileName: string) {
        if (fileName && fileName.length > 4) {
            return fileName.substring(0, fileName.length - 4) + this.MIGRATED_EXT;
        }
        return '';
    }

    onConvertClick(file: MigrationFile) {
        // Open a dialog based on DialogElementsExampleDialog with a data.conversion reference
        const dialogRef = this.dialog.open(ConvertDialogComponent, { data: { sourceType: 'LPS' }, panelClass: "convert-dialog" });

        // When the dialog is closed...
        dialogRef.afterClosed().subscribe(result => {
            // If it's not a cancel result, then it has to be .ser or .nlbl
            if (result != 'cancel') {
                file.loading = true;
                let migrationParameters = {
                    filename: file.filename,
                    migrationType: result.sourceType,
                    output: result.glmDestinationType,
                    targetVersion: result.targetSpectrumVersion,
                    subProject: result.subProjectId,
                    bucket: '',
                    email: ''};

                this.migrationService.beginMigration(migrationParameters, result.advancedOptions).subscribe(response => {
                        this.startPingingServer();
                    }, err => {
                        this.startPingingServer();
                    });


                const migratedFileName = this.getMigratedExtensionName(file.filename);
                this.filesBeingWorkedOn.push(migratedFileName);
            }
        });
    }

    sortTable(table: Migration.Table, field?: string) {
        if (table.array?.length) {
            let array = table.array;
            const property = field ? field : table.field;
            let direction = 1;
            if (property === 'filename') {
                direction = table.fileNameDirection === 'asc' ? (field ? -1 : 1) : (field ? 1 : -1);
            } else if (property === 'createDate') {
                direction = table.createDateDirection === 'asc' ? (field ? -1 : 1) : (field ? 1 : -1);
            } else if (property === 'fileSize') {
                direction = table.fileSizeDirection === 'asc' ? (field ? -1 : 1) : (field ? 1 : -1);
            } else if (property === 'statusDetail') {
                direction = table.statusDirection === 'asc' ? (field ? -1 : 1) : (field ? 1 : -1);
            }
            if (property == 'createDate') {
                array = array.sort((a, b) => {
                    const aValue = new Date(a.createDate).getTime();
                    const bValue = new Date(b.createDate).getTime();
                    return (aValue > bValue ? 1 : -1) * direction;
                });
            } else {
                array = array.sort((a, b) => {
                    const aValue = (a as any)[property] || '';;
                    const bValue = (b as any)[property] || '';;
                    return (aValue > bValue ? 1 : -1) * direction;
                });
            }
            if (field) {
                table.field = property;
                if (property === 'filename') {
                    table.fileNameDirection = table.fileNameDirection === 'asc' ? 'desc' : 'asc';
                } else if (property === 'createDate') {
                    table.createDateDirection = table.createDateDirection === 'asc' ? 'desc' : 'asc';
                } else if (property === 'fileSize') {
                    table.fileSizeDirection = table.fileSizeDirection === 'asc' ? 'desc' : 'asc';
                } else if (property === 'statusDetail') {
                    table.statusDirection = table.statusDirection === 'asc' ? 'desc' : 'asc';
                }
            }
        }
    }



    confirmDelete(file: MigrationFile) {
        const dialogRef = this.dialog.open(DeleteWarningDialogComponent, { data: { fileName: file.filename } });

        // When the dialog is closed...
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'delete') {
                this.delete(file);
            }
        });
    }

    async delete(file: MigrationFile) {
        file.deleting = true;
        const nameOfFileBeingDeleted = file.filename;
        this.filesBeingDeleted.push(nameOfFileBeingDeleted);
        try {
            const result = await firstValueFrom(this.migrationService.deleteFile(file));
            if (result?.DeleteMarker) {
                this.refreshFileList();
                this.filesBeingDeleted.splice(this.filesBeingDeleted.findIndex(x => x === nameOfFileBeingDeleted), 1);
            }
        } catch (error) {
            this.httpError = error as Error;
        }
    }
}

