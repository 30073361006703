import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdvancedOptionsPanelComponent } from '../advanced-options-panel/advanced-options-panel.component';
import { LpsAdvancedOptions } from 'src/app/models/lps-advanced-options';

interface DialogData {
  sourceType: string
  glmDestinationType: string;
  targetSpectrumVersion: string;
  subProjectId: string;
  advancedOptions: LpsAdvancedOptions;
}

interface SpectrumVersion {
  value: string;
  label: string;
}

// # TODO: Create a new folder under components/convertDialog
@Component({
  selector: 'convert-dialog',
  templateUrl: './convert-dialog.component.html',
  styleUrls: ['./convert-dialog.component.css']

})
export class ConvertDialogComponent  {

  spectrumVersions: SpectrumVersion[] = [
    { value: '4.7.1', label: '4.7.1' },
    { value: '5.0', label: '5.0' },
    { value: '5.1', label: '5.1' },
  ];
  @Input() supportGLM = false;
  @Input() showAdditionalProperties = false;

  @ViewChild(AdvancedOptionsPanelComponent)
  private advancedOptionsPanel: AdvancedOptionsPanelComponent;

  public displayAdvancedOptions: boolean = false;
  protected advancedOptionsValid: boolean = true;

  constructor(public dialogRef: MatDialogRef<ConvertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  // Send the cancel message if the cancel button is closed
  onCancel() {
    this.dialogRef.close('cancel');
  }

  onConvert() {
    if (this.displayAdvancedOptions) {
      // Return advanced options ONLY if the user clicked the Advanced Options link
      this.data.advancedOptions = this.advancedOptionsPanel.getAdvancedOptionSelections();
    }
    this.dialogRef.close(this.data)
  }

  filterSubProjectId(e: any) {
    const invalidCharsRegExp: RegExp = /[^\w]/gi;
    const t: EventTarget = e.target;
    if (t instanceof HTMLInputElement) {
      t.value = t.value.replace(invalidCharsRegExp, '');
    }
    this.data.subProjectId = this.data.subProjectId.replace(invalidCharsRegExp, '');
  }

  public advancedOptionsClicked() {
    this.displayAdvancedOptions = true;
  }

  public convertDisabled(): boolean {
    return (!this.data.targetSpectrumVersion || !this.data.subProjectId || !this.advancedOptionsValid);
  }

  public advancedOptionsValidationHandler(isValid: boolean) {
    this.advancedOptionsValid = isValid;
  }

}